import React, { lazy } from "react";
import { useRoutes, Navigate } from 'react-router-dom';
import MinimalLayout from "../layout/Login/MinimalLayout";
import Loadable from '../ui-component/Loadable';
import config from '../config';
import { useSelector } from "react-redux";

// MAIN PAGES
const MainLayout = Loadable(lazy(() => import("../layout/Prosp/MainLayout/index")));
// const Dashboard = Loadable(lazy(() => import("./views/Dashboard/Resumo")));
const ConsumoMensal = Loadable(lazy(() => import('../views/Prosp/Relatorios/Consumo/ConsumoMensal')));
const ConsumoHorario = Loadable(lazy(() => import('../views/Prosp/Relatorios/Consumo/ConsumoHorario')));
const ConsumoDiario = Loadable(lazy(() => import('../views/Prosp/Relatorios/Consumo/ConsumoDiario')));
const DistribuicaoMensal = Loadable(lazy(() => import('../views/Prosp/Relatorios/Distribuicao/DistribuicaoMensal')));
const DistribuicaoHorario = Loadable(lazy(() => import('../views/Prosp/Relatorios/Distribuicao/DistribuicaoHorario')));
const AcompanhamentoMensal = Loadable(lazy(() => import('../views/Prosp/Relatorios/Acompanhamento/AcompanhamentoMensal')));
const AcompanhamentoHorario = Loadable(lazy(() => import('../views/Prosp/Relatorios/Acompanhamento/AcompanhamentoHorario')));
const Premio = Loadable(lazy(() => import('../views/Prosp/Premio/Premio')));
const PremioEstocastico = Loadable(lazy(() => import('../views/Prosp/Premio/PremioEstocastico')));
const PremioPercentilSazo = Loadable(lazy(() => import('../views/Prosp/Premio/PremioPercentilSazo')));
const PremioPercentilFlex = Loadable(lazy(() => import('../views/Prosp/Premio/PremioPercentilFlex')));
const ExportData = Loadable(lazy(() => import('../views/Prosp/Dados/Export')));
const Historico = Loadable(lazy(() => import('../views/Prosp/Dados/Historico')));

// AUTH PAGES
const Login = Loadable(lazy(() => import('../layout/Login/index')));

export default function Routes() {
    let isAuthenticated = useSelector(state => state.Login.isAuthenticated);

    const AuthRoutes = {
        path: '/',
        element: !isAuthenticated ? <MinimalLayout /> : <Navigate to="/prosp"/>,
        children: [
            {
                path: '/login',
                element: <Login />
            },
        ]
    };
    const MainRoutes = {
        path: '/',
        element: isAuthenticated ? <MainLayout /> : <Navigate to="/login"/>,
        children: [
            {
                path: '/',
                element: <ConsumoMensal />
            },
            {
                path: '/prosp',
                element: <ConsumoMensal />
            },
            {
                path: '/prosp/dashboard',
                element: <ConsumoMensal />
            },
            {
                path: '/prosp/relatorio/consumo/mensal',
                element: <ConsumoMensal />
            },
            {
                path: '/prosp/relatorio/consumo/diario',
                element: <ConsumoDiario />
            },
            {
                path: '/prosp/relatorio/consumo/horario',
                element: <ConsumoHorario />
            },
            {
                path: '/prosp/relatorio/distribuicao/mensal',
                element: <DistribuicaoMensal />
            },
            {
                path: '/prosp/relatorio/distribuicao/horario',
                element: <DistribuicaoHorario />
            },
            {
                path: '/prosp/relatorio/acompanhamento/mensal',
                element: <AcompanhamentoMensal />
            },
            {
                path: '/prosp/relatorio/acompanhamento/horario',
                element: <AcompanhamentoHorario />
            },
            {
                path: '/prosp/relatorio/premio',
                element: <Premio />
            },
            {
                path: '/prosp/relatorio/premio-estocastico',
                element: <PremioEstocastico />
            },
            {
                path: '/prosp/relatorio/premio-percentil-sazo',
                element: <PremioPercentilSazo />
            },
            {
                path: '/prosp/relatorio/premio-percentil-flex',
                element: <PremioPercentilFlex />
            },
            {
                path: '/prosp/dados/download',
                element: <ExportData />
            },
            {
                path: '/prosp/dados/historico',
                element: <Historico />
            },
            {
                path: '*',
                element: <Navigate to="/prosp"/>
            },
        ]
    };

    
    return useRoutes([MainRoutes, AuthRoutes], config.basename);
};