const INICIAL_STATE = {
    cnpj: '88.610.191/0039-27',
    tipoAgente: 'Consumidor Livre',
    submercado: 'SUL',
    ramoAtividade: 'METALURGIA E PRODUTOS DE METAL',
    qtdCarga: '1',
    infoMercado: [],
    preco: 200,
    pSup: 95,
    pInf: 5,
    fSup: 10,
    fInf: 10,
    mSup: 30,
    mInf: 30,
    sSup: 10,
    sInf: 10,
    percEstocastico: 75,
    alpha: 50,
    diaSemanaIni: 1,
    diaSemanaEnd: 7,
    selectRamoAtividade: 'ALIMENTICIOS',
    selectSubmercado: 'SUDESTE',
    selectTipoAgente: 'Consumidor Especial',
    dateIni: "2021-01-15",
    dateEnd: "2022-07-15",
    dateMonth: "2022-07-15",
    nomeCnpj: "MUNDIAL S.A. - PRODUTOS DE CONSUMO",
    isClient: true,
    isClientCustom: true,
    typeFilterHook: false,
    modLimitada: true,
    modSemLimite: true,
    typeExportSelecao: true,
    cnae: '13.40-5 - Acabamentos em fios, tecidos e artefatos têxteis',
    cnaeDados: null
};

export default function Produto(state = INICIAL_STATE, action) {
    switch (action.type) {
        case 'SET_COD_CARGA':
            return { ...state, codCarga: action.codCarga };
        case 'SET_TIPO_AGENTE':
            return { ...state, tipoAgente: action.tipoAgente };
        case 'SET_SUBMERCADO':
            return { ...state, submercado: action.submercado };
        case 'SET_RAMO_ATIVIDADE':
            return { ...state, ramoAtividade: action.ramoAtividade };
        case 'SET_QTD_CARGA':
            return { ...state, qtdCarga: action.qtdCarga };
        case 'SET_CNPJ':
            return { ...state, cnpj: action.cnpj };
        case 'SET_PRECO':
            return { ...state, preco: action.preco };
        case 'SET_P_SUP':
            return { ...state, pSup: action.pSup };
        case 'SET_P_INF':
            return { ...state, pInf: action.pInf };
        case 'SET_M_SUP':
            return { ...state, mSup: action.mSup };
        case 'SET_M_INF':
            return { ...state, mInf: action.mInf };
        case 'SET_S_SUP':
            return { ...state, sSup: action.sSup };
        case 'SET_S_INF':
            return { ...state, sInf: action.sInf };
        case 'SET_DATE_INI':
            return { ...state, dateIni: action.dateIni };
        case 'SET_DATE_END':
            return { ...state, dateEnd: action.dateEnd };
        case 'SET_DIA_SEMANA_INI':
            return { ...state, diaSemanaIni: action.diaSemanaIni };
        case 'SET_DIA_SEMANA_END':
            return { ...state, diaSemanaEnd: action.diaSemanaEnd };
        case 'SET_NOME_CNPJ':
            return { ...state, nomeCnpj: action.nomeCnpj };
        case 'SET_DATE_MONTH':
            return { ...state, dateMonth: action.dateMonth };
        case 'SET_FLEX_SUP':
            return { ...state, fSup: action.fSup };
        case 'SET_FLEX_INF':
            return { ...state, fInf: action.fInf };
        case 'SET_IS_CLIENT':
            return { ...state, isClient: action.isClient };
        case 'SET_IS_CLIENT_CUSTOM':
            return { ...state, isClientCustom: action.isClientCustom };
        case 'SET_LIST_MERCADO':
            return { ...state, infoMercado: action.infoMercado };
        case 'SET_SELECT_RAMO':
            return { ...state, selectRamoAtividade: action.selectRamoAtividade };
        case 'SET_SELECT_CNAE':
            return { ...state, cnae: action.cnae };
        case 'SET_SELECT_CNAE_DADOS':
                return { ...state, cnaeDados: action.cnaeDados };
        case 'SET_SELECT_SUBMERCADO':
            return { ...state, selectSubmercado: action.selectSubmercado };
        case 'SET_SELECT_TIPO_AGENTE':
            return { ...state, selectTipoAgente: action.selectTipoAgente };
        case 'SET_TYPE_FILTRO':
            return { ...state, typeFilterHook: action.typeFilterHook };
        case 'SET_LIMITACAO_MOD':
            return { ...state, modLimitada: action.modLimitada };
        case 'SET_MOD_SEM_LIMITE':
            return { ...state, modSemLimite: action.modSemLimite };
        case 'SET_TYPE_EXPORT_SELECAO':
            return { ...state, typeExportSelecao: action.typeExportSelecao };
        case 'SET_PERC_PREMIO_ESTOCASTICO':
            return { ...state, percEstocastico: action.percEstocastico };
        case 'SET_ALPHA_PREMIO_ESTOCASTICO':
            return { ...state, alpha: action.alpha };
        default:
            return state;
    }
}
