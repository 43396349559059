const INICIAL_STATE = {
    loading: null,
};

export default function Loading(state = INICIAL_STATE, action) {
    switch (action.type) {
        case 'SET_BI_LOADER':
            return { ...state, loading: action.loading };
        default:
            return state;
    }
}
