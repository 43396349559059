const INICIAL_STATE = {
    isAuthenticated: localStorage.getItem('access_token') ? true : false,
    isPremium: localStorage.getItem('i_p'),
};

export default function isLoading(state = INICIAL_STATE, action) {
    switch (action.type) {
        case 'SET_IS_LOGGED':
            return { ...state, isAuthenticated: action.isAuthenticated };
        case 'SET_IS_PREMIUM':
            return { ...state, isPremium: action.isPremium };
        default:
            return state;
    }
}
