import Produto from './Produto';
import ProdutoAcomp from './ProdutoAcomp';
import Loading from './Loading';
import Layout from './Layout';
import Login from './Login';
import Export from './Export';

export default {
    Produto,
    ProdutoAcomp,
    Loading,
    Layout,
    Login,
    Export
};
