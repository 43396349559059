import ReactDOM from "react-dom";

// third party
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";


// project imports
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ConfigureStore from "./store/ConfigStore";

// style + assets
import "./assets/scss/style.scss";

const store = ConfigureStore();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
serviceWorker.register();
