import React, { lazy } from "react";
import { useRoutes, Navigate } from 'react-router-dom';
import MinimalLayout from "../layout/Login/MinimalLayout";
import Loadable from '../ui-component/Loadable';
import config from '../config';
import { useSelector } from "react-redux";

// MAIN PAGES
const MainLayout = Loadable(lazy(() => import("../layout/Acompanhamento/MainLayout/index")));
// const Dashboard = Loadable(lazy(() => import("./views/Dashboard/Resumo")));
const AcompanhamentoMensal = Loadable(lazy(() => import('../views/Acompanhamento/Relatorios/Acompanhamento/AcompanhamentoMensal')));
const AcompanhamentoHorario = Loadable(lazy(() => import('../views/Acompanhamento/Relatorios/Acompanhamento/AcompanhamentoHorario')));
const BaseContratos = Loadable(lazy(() => import('../views/Acompanhamento/Contratos/BaseContrato')));
const Premio = Loadable(lazy(() => import('../views/Acompanhamento/Premio/Premio')));
const ExportData = Loadable(lazy(() => import('../views/Acompanhamento/Dados/Export')));
const Resumo = Loadable(lazy(() => import('../views/Acompanhamento/Resumo/Resumo')));
const VisaoGeralPortifolio = Loadable(lazy(() => import('../views/Acompanhamento/Portifolio/VisaoGeral')));
const ResultadosPortifolio = Loadable(lazy(() => import('../views/Acompanhamento/Portifolio/Resultados')));

// AUTH PAGES
const Login = Loadable(lazy(() => import('../layout/Login/index')));

export default function Routes() {
    let isAuthenticated = useSelector(state => state.Login.isAuthenticated);

    const AuthRoutes = {
        path: '/',
        element: !isAuthenticated ? <MinimalLayout /> : <Navigate to="/acompanhamento"/>,
        children: [
            {
                path: '/login',
                element: <Login />
            },
        ]
    };
    
    const MainRoutes = {
        path: '/',
        element: isAuthenticated ? <MainLayout /> : <Navigate to="/login"/>,
        children: [
            {
                path: '/',
                element: <Resumo />
            },
            {
                path: '/acompanhamento',
                element: <Resumo />
            },
            {
                path: '/acompanhamento/resumo',
                element: <Resumo />
            },
            {
                path: '/acompanhamento/relatorio/mensal',
                element: <AcompanhamentoMensal />
            },
            {
                path: '/acompanhamento/relatorio/horario',
                element: <AcompanhamentoHorario />
            },
            {
                path: '/acompanhamento/portifolio/visao-geral',
                element: <VisaoGeralPortifolio />
            },
            {
                path: '/acompanhamento/portifolio/resultados',
                element: <ResultadosPortifolio />
            },
            {
                path: '/acompanhamento/relatorio/premio',
                element: <Premio />
            },
            {
                path: '/acompanhamento/contratos/base',
                element: <BaseContratos />
            },
            {
                path: '/acompanhamento/dados/download',
                element: <ExportData />
            },
            {
                path: '*',
                element: <Navigate to="/acompanhamento"/>
            },
        ]
    };

    return useRoutes([MainRoutes, AuthRoutes], config.basename);
};