import React, {Suspense} from "react";
import { useSelector } from 'react-redux';

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

// routing
import Routeprosp from "./routes/prosp";
import RouteAcompanhamento from "./routes/acomp";

// themes
import themes from "./themes";

// project imports
import NavigationScroll from "./layout/NavigationScroll";

// ==============================|| APP ||============================== //

function App() {
    const customization = useSelector((state) => state.Layout);
    const systemType = customization.system

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider  theme={themes(customization)}>
                <CssBaseline>
                    <NavigationScroll>
                    <Suspense fallback={<h1>Loading profile...</h1>}>
                    {systemType === 'prosp' ?
                        <Routeprosp /> :
                        <RouteAcompanhamento />
                        }
                    </Suspense>
                    </NavigationScroll>
                </CssBaseline>
            </ThemeProvider>
        </StyledEngineProvider>
    );

}

export default App;
