
const initialValue = JSON.parse(localStorage.getItem("client_data"));
let firstValue = '';
let c = '';
let n = '';
let cContrato = '';
let carga = '';
let sub = '';
let r = '';
let tEnergia = '';

if (initialValue !== null) {
    firstValue = initialValue[0]
    c = firstValue.cnpj;
    n = firstValue.nome;
    cContrato = firstValue.codigoContrato;
    carga = firstValue.qtdCarga;
    sub = firstValue.submercado;
    r = firstValue.ramoAtividade;
    tEnergia = firstValue.tipoEnergia;
};

const INICIAL_STATE = {
    cnpjAcomp: c,
    tipoAgenteAcomp: tEnergia,
    submercadoAcomp: sub,
    ramoAtividadeAcomp: r,
    qtdCargaAcomp: carga,
    contratoAcomp: cContrato,
    listContratoAcomp: [{codigoContrato: cContrato}],
    InfoMercadoAcomp: [],
    dateIniAcomp: "2019-01-15",
    dateEndAcomp: "2022-07-15",
    dateMonthAcomp: "2022-07-15",
    nomeCnpjAcomp: n,
};

export default function ProdutoAcomp(state = INICIAL_STATE, action) {
    switch (action.type) {
        case 'SET_TIPO_AGENTE_ACOMP':
            return { ...state, tipoAgenteAcomp: action.tipoAgenteAcomp };
        case 'SET_SUBMERCADO_ACOMP':
            return { ...state, submercadoAcomp: action.submercadoAcomp };
        case 'SET_RAMO_ATIVIDADE_ACOMP':
            return { ...state, ramoAtividadeAcomp: action.ramoAtividadeAcomp };
        case 'SET_QTD_CARGA_ACOMP':
            return { ...state, qtdCargaAcomp: action.qtdCargaAcomp };
        case 'SET_CNPJ_ACOMP':
            return { ...state, cnpjAcomp: action.cnpjAcomp };
        case 'SET_DATE_INI_ACOMP':
            return { ...state, dateIniAcomp: action.dateIniAcomp };
        case 'SET_DATE_END_ACOMP':
            return { ...state, dateEndAcomp: action.dateEndAcomp };
        case 'SET_NOME_CNPJ_ACOMP':
            return { ...state, nomeCnpjAcomp: action.nomeCnpjAcomp };
        case 'SET_DATE_MONTH_ACOMP':
            return { ...state, dateMonthAcomp: action.dateMonthAcomp };
        case 'SET_LIST_MERCADO_ACOMP':
            return { ...state, infoMercadoAcomp: action.infoMercadoAcomp };
        case 'SET_CONTRATO_ACOMP':
            return { ...state, contratoAcomp: action.contratoAcomp };
        case 'SET_LIST_CONTRATO_ACOMP':
            return { ...state, listContratoAcomp: action.listContratoAcomp };
        default:
            return state;
    }
}
