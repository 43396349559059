// project imports
import config from '../../config';

const INICIAL_STATE = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    page: 'resumo',
    system: localStorage.getItem('system_type') ? localStorage.getItem('system_type') : 'prosp',
};

export default function Layout(state = INICIAL_STATE, action) {
    let id;
    switch (action.type) {
        case 'SET_PAGE':
            return {
                ...state,
                page: action.page
            };
        case 'SET_SYSTEM':
            return {
                ...state,
                system: action.system
            };
        case 'SET_MENU_OPENED':
            return {
                ...state,
                opened: action.opened
            };
        case 'SET_MENU':
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        default:
            return state;
    }
}

