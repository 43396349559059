const INICIAL_STATE = {
    exportDateIni: "2021-01-15",
    exportDateEnd: "2022-03-15",
    exportCnpj: '',
    exportPreco: 200,
    exportFSup: 10,
    exportFInf: 10,
    exportMSup: 30,
    exportMInf: 30,
    exportSSup: 10,
    exportSInf: 10,
    exportSubmercado: 'SUDESTE',
    exportRamo: 'ALIMENTICIOS',
    exportCnae: '13.40-5 - Acabamentos em fios, tecidos e artefatos têxteis',
    exportCnaeDados: [{cnae:'13.40-5 - Acabamentos em fios, tecidos e artefatos têxteis'}],
    exportTipoAgente: 'Consumidor Livre',
};

export default function Export(state = INICIAL_STATE, action) {
    switch (action.type) {
        case 'SET_EXPORT_DATE_INI':
            return {
                ...state,
                exportDateIni: action.exportDateIni
            };
        case 'SET_EXPORT_DATE_END':
            return {
                ...state,
                exportDateEnd: action.exportDateEnd
            };
        case 'SET_EXPORT_CNPJ':
            return {
                ...state,
                exportCnpj: action.exportCnpj
            };
        case 'SET_EXPORT_PRECO':
            return {
                ...state,
                exportPreco: action.exportPreco
            };
        case 'SET_EXPORT_FSUP':
            return {
                ...state,
                exportFSup: action.exportFSup
            };
        case 'SET_EXPORT_FINF':
            return {
                ...state,
                exportFInf: action.exportFInf
            };
        case 'SET_EXPORT_MSUP':
            return {
                ...state,
                exportMSup: action.exportMSup
            };
        case 'SET_EXPORT_MINF':
            return {
                ...state,
                exportMInf: action.exportMInf
            };
        case 'SET_EXPORT_SSUP':
            return {
                ...state,
                exportSSup: action.exportSSup
            };
        case 'SET_EXPORT_SINF':
            return {
                ...state,
                exportSInf: action.exportSInf
            };
        case 'SET_EXPORT_SUBMERCADO':
            return {
                ...state,
                exportSubmercado: action.exportSubmercado
            };
        case 'SET_EXPORT_TIPO_AGENTE':
            return {
                ...state,
                exportTipoAgente: action.exportTipoAgente
            };
        case 'SET_EXPORT_RAMO':
            return {
                ...state,
                exportRamo: action.exportRamo
            };
        case 'SET_EXPORT_CNAE':
            return {
                ...state,
                exportCnae: action.exportCnae
            };
        case 'SET_EXPORT_CNAE_DADOS':
                return {
                    ...state,
                    exportCnaeDados: action.exportCnaeDados
                };
        default:
            return state;
    }
}

